import React from "react"
import PropTypes from "prop-types"

const CheckboxInput = ({name, value, onChange, placeholder}) => {
    return (
        <div>
            <input id={name} type={"checkbox"} className={"mr-3"} name={name} value={value} checked={value} onChange={e => onChange(e)}/>
            <label htmlFor={name}  className={"mb-0"}>{placeholder}</label>
{name == "registered_cvg" ? <><br/><span>I agree to <a href='https://www.theunion.org/privacy-policy' className={"whitecolor"} target='_blank'>The Union's Privacy Policy</a>*</span></> : ""}
        </div>
    )
}

CheckboxInput.propTypes = {
    name:       PropTypes.string.isRequired,
    value:      PropTypes.string.isRequired,
    onChange:   PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
}

export default CheckboxInput
